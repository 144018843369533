import ApexCharts from 'apexcharts';

export default class StoreAdminDashboardTypology {
  
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.display_chart_age();
    this.display_chart_type();
  }

  display_chart_age() {
    var data = $("#chart_age").data("age");

    var options = {
        series: [{
        data: data,
      }],
        chart: {
        type: 'bar',
        height: 300,
        offsetY: 20,
        toolbar: {
          show: false,
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['< 60 ans', '60 - 65 ans', '65 - 70 ans', '70 - 75 ans', '75 - 80 ans', 'Plus de 80 ans'],
      },
      colors: ["#3970c7"],
    };

    var chart = new ApexCharts(document.querySelector("#chart_age"), options);
    chart.render();
  }

  display_chart_type() {
    var data = $("#chart_type").data("type");
    var options = {
        series: data,
        chart: {
          type: 'donut',
          height: 300,
          offsetY: 20,
        },
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      colors: ["#70a7dc", "#f9cee6", "#3970C7"],
      labels: ['Homme', 'Femme', 'Couple'],
    };

    var chart = new ApexCharts(document.querySelector("#chart_type"), options);
    chart.render();
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $("#chart_age").empty();
    $("#chart_type").empty();
  }
}
