import { Calendar } from 'fullcalendar';
import listPlugin from '@fullcalendar/list';

export default class AdminCalendar {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    var self = this;
    this.calendar = new Calendar(document.getElementById('calendar'), {
      plugins: [ listPlugin ],
      locale: 'fr',
      events: '/events/data/',
      firstDay: 1,
      dayMaxEventRows: 4,
      allDaySlot: false,
      slotMinTime: "06:00:00",
      slotMaxTime: "21:00:00",
      /*eventDidMount: function(info) {
        if (info.event.extendedProps.is_outdated){
          info.el.style.backgroundColor = '#D3D3D3';
        } else {
          if (info.event.extendedProps.is_late_withdrawal){
            info.el.style.backgroundColor = '#F5B041';
          }
          else if (info.event.extendedProps.nb_companions == info.event.extendedProps.nb_companions_max){
            info.el.style.backgroundColor = '#59B99F';
          } else if (info.event.extendedProps.nb_companions < info.event.extendedProps.nb_companions_max){
            info.el.style.backgroundColor = '#6ACAE3';
          }
        }
      },*/
      eventContent: function( info ) {
        let el = document.createElement('div')
        el.innerHTML = info.event.title;
        el.classList.add('fc-event-title');
        if (info.event.extendedProps.is_outdated){
          el.style.backgroundColor = '#D3D3D3';
        } else {
          if (info.event.extendedProps.is_late_withdrawal){
            el.style.backgroundColor = '#F5B041';
          }
          else if (info.event.extendedProps.nb_companions == info.event.extendedProps.nb_companions_max){
            el.style.backgroundColor = '#59B99F';
          } else if (info.event.extendedProps.nb_companions < info.event.extendedProps.nb_companions_max){
            el.style.backgroundColor = '#6ACAE3';
          }
        }
        let arrayOfDomNodes = [ el ]
        return { domNodes: arrayOfDomNodes }

        //return {html: info.event.title};
      },
      dateClick: (info) => {
        if (window.location.href.includes("bo")) {
          $('#date-field').val(info.dateStr);
          $("#clicked-day-name").html(new Date(info.dateStr).toLocaleString('fr-FR', { weekday: 'long' }));
          $('#cellModalCenter').modal('show');
        }else{
         self.displayDateModal(info.dateStr);
        }
      },
      eventClick: (info) => {
        this.clearFields();

        $('#eventModalCenter').modal('show');
        $("#form").addClass("d-none");

        if (info.event.extendedProps.is_outdated && !window.location.href.includes("bo")){
          $("#form-btn").addClass("d-none");
        }else{
          $("#form-btn").removeClass("d-none");
        }

        const eventStart = new Date(info.event.start).toLocaleString('fr-FR', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        });

        $("#eventModalLongTitle").html(`Événement du ${eventStart}`);
        this.loadEvent(info.event.id);
        
      },
      headerToolbar: {
        start: "prev,next",
        center: "title",
        end: "dayGridMonth,timeGridWeek,timeGridDay,ListDisplay",
      },
      customButtons: {
        ListDisplay: {
          text: "Liste",
          click: function () {
            self.calendar.changeView('listWeek');
          }
        }
      },
    });

    this.calendar.render();

    var date = $("#calendar").data("date");
    this.calendar.gotoDate(date);

    if (window.location.href.includes("magasins")) {
      this.calendar.on('eventsSet', function() {
        self.getSubscriptionData();
      });
    }

    $(".fc-dayGridMonth-button").text("Mois");
    $(".fc-timeGridWeek-button").text("Semaine");
    $(".fc-timeGridDay-button").text("Jour");
    $(".fc-ListDisplay-button").text("Liste");
    
    $(".fc-next-button, .fc-prev-button, .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-ListDisplay-button").on('click', (event) => {
      $(".fc-dayGridMonth-button").text("Mois");
      $(".fc-timeGridWeek-button").text("Semaine");
      $(".fc-timeGridDay-button").text("Jour");
      $(".fc-ListDisplay-button").text("Liste");
    });

    $("#submit-event-form").on('click', (event) => {
      event.preventDefault();
      this.submitEventForm();
    });

    $("#submit-event-update").on('click', (event) => {
      event.preventDefault();
      this.submitEventForm("update", $("#event-id").html());
    });

    $("#form-btn").on('click', (event) => {
      event.preventDefault();
      $("#form").removeClass("d-none");
      $("#companions-list").addClass("d-none");
      $("#no-c-msg").addClass("d-none");
      $("#list-footer").addClass("d-none").removeClass("d-flex");
    });

    $("#list-btn").on('click', (event) => {
      event.preventDefault();
      if ($("#nb_companions").html() > 0) {
        $("#companions-list").removeClass("d-none");
      } else {
        $("#no-c-msg").removeClass("d-none");
      }
      $("#form").addClass("d-none");
      $("#list-footer").removeClass("d-none").addClass("d-flex");
    });

    $("#eventModalCenter").on('hidden.bs.modal', (event) => {
      $("#form").addClass("d-none");
      $("#companions-list").addClass("d-none");
      $("#no-c-msg").addClass("d-none");
      $("#list-footer").remove("d-none").addClass("d-flex");
    });

    // filters
    var $dropdownTitle = $("#dropdown-title");

    $("#type-all, #type-desertion, #type-available, #type-full").on('click', function(event) {
      var type = event.target.id.split("-")[1];

      switch (type) {
        case "all":
          $dropdownTitle.html("Tous");
          break;
        case "desertion":
          $dropdownTitle.html("<span class='text-warning pr-1'>●</span> Désistements -48h");
          break;
        case "available":
          $dropdownTitle.html("<span class='text-info pr-1'>●</span> Places restantes");
          break;
        case "full":
          $dropdownTitle.html("<span class='text-success pr-1'>●</span> Complet");
          break;
      }
      
      self.calendar.getEvents().forEach(function(event) {
        var isLateWithdrawal = event.extendedProps.is_late_withdrawal || event.extendedProps.is_outdated;
        var isAvailable = !event.extendedProps.already_registered && (!event.extendedProps.is_full || event.extendedProps.is_outdated);
        var isFull = event.extendedProps.is_full || event.extendedProps.is_outdated;
      
        if ((type == "all") ||
            (type == "desertion" && isLateWithdrawal) ||
            (type == "available" && isAvailable) ||
            (type == "full" && isFull)) {
          event.setProp("display", "block");
        } else {
          event.setProp("display", "none");
        }
      });      
    });
  }

  loadEvent(id) {
    var self = this;

    $("#companions-list-body").empty();
    $.ajax({
      type: "GET",
      url: "/events/" + id,
      success: (response) => {
        $("#event-id").html(response.event.id);
        
        if (response.companions.length > 0) {
          $("#no-c-msg").addClass("d-none");
          const table = $("#companions-list-body");
          response.companions.forEach((companion, i) => {
            
            var badge = "<span class='badge badge-light px-2'>?</span>";
            
            if (response.participations[i].status == "present") {
              badge = "<span class='badge badge-success'>Présent</span>";
            } else if (response.participations[i].status == "absent") {
              badge = "<span class='badge badge-danger'>Absent</span>";
            }

            var actions = "";
            if (response.is_today) {
              actions = `
                <div class="dropdown align-self-end">
                  <button class="btn fs-16 btn-sm text-muted p-0 dropdown-toggle shadow-none" style="border:0px;" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                  </button>
                  <div class="dropdown-menu"> 
                    <a class="dropdown-item" rel="nofollow" data-method="put" href="/events/${id}/update-participation-status/${companion.id}/present">
                      <i class="mdi mdi-account-check text-success mr-1"></i> Présent
                    </a>
                    <a class="dropdown-item" rel="nofollow" data-method="put" href="/events/${id}/update-participation-status/${companion.id}/absent">
                      <i class="mdi mdi-account-remove text-danger mr-1"></i> Absent
                    </a>
                    <a class="dropdown-item" rel="nofollow" data-method="put" href="/events/${id}/update-participation-status/${companion.id}/neutral">
                      <i class="mdi mdi-account-minus text-secondary mr-1"></i> Pas de statut
                    </a>
                  </div>
                </div>
              `;
            }else{
              if (window.location.href.includes("bo")) {
                actions = `
                  <a class="dropdown-item" rel="nofollow" data-method="put" data-confirm="Êtes-vous sûr de vouloir retirer ${companion.first_name} ${companion.last_name} de cet évènement ?" href="/events/${id}/delete-participation/${companion.id}/${response.event.date}">
                    <i class="mdi mdi-account-off text-danger mr-1"></i> Retirer
                  </a>`;
              }
            }
            table.append(`
              <tr>
                <td class="align-middle"><div class='rounded-circle border mx-auto circle-img img-4'><img src="${response.companions_profile_pictures[i]}" alt="Profile Picture" class="rounded-circle h-100 w-100"></div></td>
                <td class="align-middle">${badge}</td>
                <td class="align-middle">${companion.first_name} ${companion.last_name}</td>
                <td class="align-middle">${response.companions_registred_at_dates[i]}</td>
                <td class="align-middle">${response.companions_confirmed_at_dates[i]}</td>
                <td class="align-middle">${response.companions_qr_scanned_at_dates[i]}</td>
                <td class="align-middle">
                 ${actions}
                </td>
              </tr>
            `);
          });

          $("#nb_companions").html(response.companions.length);
          $("#nb_companions_max").html(response.event.nb_companions);
          $("#companions-list").removeClass("d-none");
        } else {
          $("#companions-list").addClass("d-none");
          $("#no-c-msg").removeClass("d-none");
          $("#form-btn").removeClass("d-none");
        }

        var eventDate = new Date(response.event.date);

        var startTimeHours = eventDate.getHours();
        var startTimeMinutes = eventDate.getMinutes();
        
        var formattedStartTime = (startTimeHours < 10 ? "0" : "") + startTimeHours + ":" + (startTimeMinutes < 10 ? "0" : "") + startTimeMinutes;

        $("#date-field-e").val(this.formatDate(new Date(response.event.date), "yyyy-mm-dd"));
        $("#start-time-field-e").val(formattedStartTime);
        $("#all-day-field-e").prop("checked", response.event.is_recurrent || response.is_a_recurrence);
        
        if ($("#store-select-e").length > 0) {
          $("#store-select-e").val(response.event.store_id);
          $("#store-select-e").trigger("change");
        }

        if (response.is_a_recurrence) {
          $("#all-day-field-e").prop("disabled", true);
        }else{
          $("#all-day-field-e").prop("disabled", false);
        }

        $("#event-duration-e").val(self.formatDuration(response.event.duration));

        $("#nb-companions-field-e").val(response.event.nb_companions);

        if(response.is_a_recurrence){
          $("#recurrence-msg-wrapper").removeClass("d-none");
          $("#check-wrapper").addClass("d-none");
        }else{
          $("#recurrence-msg-wrapper").addClass("d-none");
          $("#check-wrapper").removeClass("d-none");
        }

        $("#clicked-day-name-e").html(new Date(response.event.date).toLocaleString('fr-FR', { weekday: 'long' }));
      
        if (response.is_outdated) {
          $("#delete-links-wrapper").addClass("d-none");
        }else{
          $("#delete-links-wrapper").removeClass("d-none");
        }
        
        $("#delete-link").attr("href", "/events/" + response.event.id);
        $("#delete-link-recurrent").attr("href", "/events/" + response.event.id);
        if(response.is_recurrent) {
          $("#delete-link-recurrent").removeClass("d-none");
          $("#delete-link").addClass("d-none");
        }
        else {
           $("#delete-link-recurrent").addClass("d-none");
           $("#delete-link").removeClass("d-none");
        }
      },
      error: (error) => {
      }
    });
  }

  submitEventForm(action = "create", id = null) {
    const form = action === "create" ? $("#event-form")[0] : $("#event-update-form")[0];
    const method = action === "create" ? "POST" : "PATCH";
    const url = action === "create" ? "/events" : `/events/${id}`;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      form.classList.add("was-validated");
    } else {
      const formData = $(form).serialize();
      $.ajax({
        type: method,
        url,
        data: formData,
        success: (response) => {
        }
      });
    }
  }
  
  clearFields() {
    const elementIDs = [
      'date-field', 'start-time-field', 'duration-field', 'nb-companions-field',
      'date-field-e', 'start-time-field-e', 'duration-field-e', 'nb-companions-field-e'
    ];
  
    for (const id of elementIDs) {
      $('#' + id).val('');
    }
  }

  formatDate(date, format = "dd-mm-yyyy") {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    if (format === "dd-mm-yyyy") {
      return `${day}-${month}-${year}`;
    }else{
      return `${year}-${month}-${day}`;
    }
  }

  getSubscriptionData() {
    const start_date = this.formatDate(new Date(this.calendar.getDate().getFullYear(), this.calendar.getDate().getMonth(), 1), "yyyy-mm-dd");

    $.ajax({
      type: "GET",
      url: `/events/month-subscription-data/${start_date}`,
      success: (response) => {
        var incomingPlacedHours = parseFloat(response.incoming_placed_hours);
        var outdatedPlacedHours = parseFloat(response.outdated_placed_hours);

        $("#placed-hours").html(incomingPlacedHours.toFixed(1));
        $("#consumed-hours").html(outdatedPlacedHours.toFixed(1));
      }
    });    
  }

  displayDateModal(date) {
    var self = this
    $.ajax({
      type: "GET",
      url: `/events/day-status/${date}`,
      success: (response) => {
        var day_status = response.day_status;
        self.clearFields();
        if (day_status === "available" || day_status === "unavailable" || day_status === "outdated") {
          if (day_status === "available") {
            $('#date-field').val(date);
            $("#clicked-day-name").html(new Date(date).toLocaleString('fr-FR', { weekday: 'long' }));
            if (response.remaining_hours > 0) {
              $("#remaing-hours").html(" - " + response.remaining_hours.toFixed(1) + " heures restantes");
            }
            $('#cellModalCenter').modal('show');
          } else if (day_status === "unavailable") {
            var code = response.code;
            if (code == 1) {
              $('#noSubscriptionModal').modal('show');
            } else if (code == 2) {
              $('#notInSubscriptionPeriod').modal('show');
            } else if (code == 3) {
              $('#subscriptionWarnModal').modal('show');
            }
          }
        }
      },
      error: (error) => {
        reject(error);
      }
    });
  }

  formatDuration(duration){
    var duration_minutes = duration;

    var hours = Math.floor(duration_minutes / 60);
    var minutes = duration_minutes % 60;

    var formatted_hours = (hours < 10) ? "0" + hours : hours;
    var formatted_minutes = (minutes < 10) ? "0" + minutes : minutes;

    var formatted_duration = formatted_hours + ":" + formatted_minutes;

    return formatted_duration
  }

  onDataChanged() {
    
  }

  onDestroy() {
    if (this.calendar) {
      this.calendar.destroy();
      this.calendar = null;
    }
  }
}